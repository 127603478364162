import { Box, BoxProps, styled } from "@mui/material";
import React from "react";

const StyledBox = styled(Box, {
    shouldForwardProp: (props) => props !== "textTransformStyle",
})<{
    textTransformStyle?: any;
    ellipsis?: any;
}>(({ textTransformStyle, ellipsis }) => ({
    textTransform: textTransformStyle || "none",
    whiteSpace: ellipsis ? "nowrap" : "normal",
    overflow: ellipsis ? "hidden" : "",
    textOverflow: ellipsis ? "ellipsis" : "",
}));

type Props = {
    ellipsis?: any;
    textTransform?:
        | "none"
        | "capitalize"
        | "uppercase"
        | "lowercase"
        | "initial"
        | "inherit";
};

export const H1Big: React.FC<BoxProps & Props> = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
}) => {
    return (
        <StyledBox
            textTransformStyle={textTransform}
            ellipsis={ellipsis ? 1 : undefined}
            component="h1"
            mb={1}
            mt={0}
            fontSize="44px"
            fontWeight="600"
            lineHeight="1.4"
            {...props}
        >
            {children}
        </StyledBox>
    );
};
export const H1: React.FC<BoxProps & Props> = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
}) => {
    return (
        <StyledBox
            textTransformStyle={textTransform}
            ellipsis={ellipsis ? 1 : undefined}
            component="h1"
            mb={1}
            mt={0}
            fontSize="30px"
            fontWeight="600"
            lineHeight="40px"
            {...props}
        >
            {children}
        </StyledBox>
    );
};

export const H2: React.FC<BoxProps & Props> = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
}) => {
    return (
        <StyledBox
            textTransformStyle={textTransform}
            ellipsis={ellipsis ? 1 : undefined}
            component="h2"
            mb={0}
            mt={0}
            fontSize="26px"
            fontWeight="600"
            lineHeight="1.4"
            {...props}
        >
            {children}
        </StyledBox>
    );
};

export const H3: React.FC<BoxProps & Props> = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
}) => {
    return (
        <StyledBox
            mb={0}
            mt={0}
            component="h3"
            fontSize="22px"
            lineHeight="30px"
            ellipsis={ellipsis ? 1 : undefined}
            textTransformStyle={textTransform}
            {...props}
        >
            {children}
        </StyledBox>
    );
};

export const H5: React.FC<BoxProps & Props> = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
}) => {
    return (
        <StyledBox
            textTransformStyle={textTransform}
            ellipsis={ellipsis ? 1 : undefined}
            component="h5"
            mb={0}
            mt={0}
            fontSize="18px"
            lineHeight="27px"
            {...props}
        >
            {children}
        </StyledBox>
    );
};

export const H6: React.FC<BoxProps & Props> = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
}) => {
    return (
        <StyledBox
            textTransformStyle={textTransform}
            ellipsis={ellipsis ? 1 : undefined}
            component="h6"
            mb={0}
            mt={0}
            fontSize="16px"
            fontWeight="600"
            lineHeight="1.3"
            {...props}
        >
            {children}
        </StyledBox>
    );
};

export const H6Link: React.FC<BoxProps & Props> = ({ children, ...props }) => {
    return (
        <H6 sx={{ textDecoration: "underline", color: "info.main" }} {...props}>
            {children}
        </H6>
    );
};

export const BigText: React.FC<BoxProps & Props> = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
}) => {
    return (
        <StyledBox
            textTransformStyle={textTransform}
            ellipsis={ellipsis ? 1 : undefined}
            component="p"
            mb={0}
            mt={0}
            lineHeight={1.3}
            fontSize="17px"
            {...props}
        >
            {children}
        </StyledBox>
    );
};
export const Paragraph: React.FC<BoxProps & Props> = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
}) => {
    return (
        <StyledBox
            textTransformStyle={textTransform}
            ellipsis={ellipsis ? 1 : undefined}
            component="p"
            mb={0}
            mt={0}
            lineHeight={1.3}
            fontSize="16px"
            {...props}
        >
            {children}
        </StyledBox>
    );
};

export const Small: React.FC<BoxProps & Props> = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
}) => {
    return (
        <StyledBox
            textTransformStyle={textTransform}
            ellipsis={ellipsis ? 1 : undefined}
            component="p"
            mb={0}
            mt={0}
            lineHeight={1.3}
            fontSize="14px"
            {...props}
        >
            {children}
        </StyledBox>
    );
};

export const Mini: React.FC<BoxProps & Props> = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
}) => {
    return (
        <StyledBox
            textTransformStyle={textTransform}
            ellipsis={ellipsis ? 1 : undefined}
            component="small"
            fontWeight={400}
            fontSize="12px"
            lineHeight="1.2"
            {...props}
        >
            {children}
        </StyledBox>
    );
};
export const Span: React.FC<BoxProps & Props> = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
}) => {
    return (
        <StyledBox
            textTransformStyle={textTransform}
            ellipsis={ellipsis ? 1 : undefined}
            component="span"
            {...props}
        >
            {children}
        </StyledBox>
    );
};

export const Tiny: React.FC<BoxProps & Props> = ({
    children,
    className,
    ellipsis,
    textTransform,
    ...props
}) => {
    return (
        <StyledBox
            textTransformStyle={textTransform}
            ellipsis={ellipsis ? 1 : undefined}
            component="small"
            fontSize="10px"
            lineHeight="1.2"
            {...props}
        >
            {children}
        </StyledBox>
    );
};

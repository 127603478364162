import { Email } from "@mui/icons-material";
import FlexBetween from "../../atoms/flex-box/FlexBetween";
import FlexBox from "../../atoms/flex-box/FlexBox";
import { Paragraph } from "../../atoms/Typography";
import { mailUrl } from "../../utils/data/common";
import { maxWidth, textColor1Light } from "../../utils/theme/themeConstants";

function Footer() {
    return (
        <FlexBetween
            padding={{
                xs: "20px",
                sm: "20px 0px",
            }}
            flexDirection={{
                xs: "column-reverse",
                sm: "row",
            }}
            gap={2}
            style={{
                maxWidth: maxWidth,
                margin: "auto",
                textAlign: "center",
            }}
            flexWrap="wrap"
        >
            <Paragraph color={textColor1Light}>
                ©2023 Blackfish Technologies Private Limited
            </Paragraph>
            <FlexBox gap={2}>
                <a href={mailUrl}>
                    <Email />
                </a>
            </FlexBox>
        </FlexBetween>
    );
}

export default Footer;

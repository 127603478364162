import { Box, Grid } from "@mui/material";
import LightButton from "../../atoms/buttons/LightButton";
import { H1 } from "../../atoms/Typography";
import { homeData } from "../../utils/data/homePage";

const TopSection = () => {
    const { heading } = homeData;
    return (
        <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems={"center"}
            minHeight={"70vh"}
        >
            <Grid item md={6} sm={12} xs={12}>
                <Box
                    className="flex flex-col gap-2 md:text-left md:mr-8"
                    alignItems={{
                        xs: "center",
                        sm: "center",
                        md: "flex-start",
                    }}
                >
                    <H1
                        fontSize={{
                            xs: "44px",
                            sm: "60px",
                        }}
                        mb={2}
                        lineHeight={1.2}
                        fontWeight={"700"}
                    >
                        {heading}
                    </H1>
                    <LightButton
                        sx={{
                            fontSize: "1rem",
                            padding: "1.6rem 4rem",
                        }}
                        href={"/#products"}
                    >
                        {"Explore"}
                    </LightButton>
                </Box>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
                <img src="/assets/landing-pc.png" alt="bg" />
            </Grid>
        </Grid>
    );
};

export default TopSection;

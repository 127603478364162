import { Box } from "@mui/material";
import React, { FC } from "react";
import { maxWidth, themeColor2 } from "../utils/theme/themeConstants";

// ===================================================
type Props = {
    children: React.ReactNode;
    elementId?: string;
};
// ===================================================

const AppContainer2: FC<Props> = ({ children, elementId }) => {
    return (
        <Box
            id={elementId}
            padding={{
                xs: "60px 20px",
                sm: "120px 0px",
            }}
            style={{
                backgroundColor: themeColor2,
                width: "100%",
                margin: "auto",
            }}
        >
            <div
                style={{
                    maxWidth: maxWidth,
                    margin: "auto",
                    textAlign: "center",
                    width: "100%",
                }}
            >
                {children}
            </div>
        </Box>
    );
};

export default AppContainer2;

import { Box, Grid } from "@mui/material";
import FlipDiv from "../../atoms/flipDiv/FlipDiv";
import { H1, H3, H5 } from "../../atoms/Typography";
import { homeData } from "../../utils/data/homePage";
import { textColor2, textColor2Light } from "../../utils/theme/themeConstants";

const ProductSection = () => {
    const { whyChooseUs } = homeData;

    return (
        <Box className="flex flex-col items-center" color={textColor2}>
            <H1 mb={1}>{whyChooseUs.title}</H1>
            <H5 mb={4} color={textColor2Light}>
                {whyChooseUs.subtitle}
            </H5>
            <Grid container className="w-full text-center" spacing={2}>
                {whyChooseUs.data.map((highlight, index) => (
                    <Grid item key={index} md={4} sm={6} xs={12}>
                        <FlipDiv
                            frontDiv={<H3 m={2}>{highlight.title}</H3>}
                            backDiv={<H5 m={2}>{highlight.subtitle}</H5>}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ProductSection;

import React, { FC, Fragment } from "react";
import Sticky from "../atoms/sticky/Sticky";
import Footer from "../page-components/common/Footer";
import Header from "../page-components/common/Header";

// ===================================================
type Props = {
    metaTitle?: string;
    metaDescription?: string;
    children: React.ReactNode;
};
// ===================================================

const AppLayout: FC<Props> = ({ children }) => {
    return (
        <Fragment>
            <div
                style={{
                    minHeight: "100vh",
                    margin: "auto",
                    textAlign: "center",
                }}
            >
                <Sticky fixedOn={0} scrollDistance={300}>
                    <Header />
                </Sticky>
                {children}
                <Footer />
            </div>
        </Fragment>
    );
};

export default AppLayout;

import { Grid } from "@mui/material";
import { H1, H5 } from "../../atoms/Typography";
import { softwareData } from "../../utils/data/products";
import { textColor2, textColor2Light } from "../../utils/theme/themeConstants";

const ExperienceSection = () => {
    const { specialists } = softwareData;
    return (
        <Grid container spacing={8} alignItems="center" color={textColor2}>
            <Grid
                item
                md={4}
                xs={12}
                display={{
                    xs: "none",
                    sm: "flex",
                }}
            >
                <img src={specialists.image} alt="blackfish" />
            </Grid>
            <Grid item md={8} xs={12} textAlign="left">
                <H1 mb={2}>{specialists.title}</H1>
                <H5 my={2} color={textColor2Light}>
                    {specialists.subtitle}
                </H5>
                <H5 my={2} color={textColor2Light}>
                    {specialists.subtitle2}
                </H5>
            </Grid>
        </Grid>
    );
};

export default ExperienceSection;

export const aboutData = {
    team: {
        title: "Our Team",
        subtitle:
            "Our team is composed of experienced blockchain developers, researchers, and industry experts who are dedicated to staying at the forefront of the decentralized web revolution. We are committed to building a diverse and inclusive team that reflects the communities we serve.",
        subtitle2:
            "Blackfish is a team of over 20+ years of professional experience that keeps growing fast. We may come from various backgrounds, but that lets us look at your projects from many perspectives. The best products come from the best people, and at Blackfish this is not only an empty claim.",
        data: [
            {
                name: "",
                logo: "/assets/iiti.png",
                height: "100px",
                border: "50px",
            },
            {
                name: "",
                logo: "/assets/iist.png",
                height: "100px",
                border: "50px",
            },
            {
                name: "",
                logo: "/assets/iitb.png",
                height: "100px",
                border: "50px",
            },
            {
                name: "",
                logo: "/assets/iitd.png",
                height: "100px",
                border: "50px",
            },
            {
                name: "",
                logo: "/assets/tr.png",
                height: "55px",
                bgcolor: "#ebebeb",
            },
            {
                name: "",
                logo: "/assets/jpmc.png",
                height: "50px",
            },
            {
                logo: "/assets/ms.webp",
                height: "50px",
            },
            {
                name: "",
                logo: "/assets/eth.png",
                height: "60px",
            },
            {
                logo: "/assets/gs.png",
                height: "60px",
            },
        ],
    },
};

import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";

const LightButton = styled(LoadingButton)({
    background: "#F5F5F5",
    border: "none",
    height: "44px",
    padding: "0 50px",
    color: "#2C3C5B",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "none",
    width: "fit-content",
    "&:hover": {
        background: "#F5F5F5",
        color: "#2C3C5B",
    },
}) as typeof LoadingButton;

export default LightButton;

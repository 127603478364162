import { calendlyUrl, mailUrl } from "./common";

export const homeData = {
    heading: "Taking your blueprints to market",
    highlights: [
        {
            title: "$10M+",
            subtitle: "ON-CHAIN Revenue",
        },

        {
            title: "300K+",
            subtitle: "Transactions Processed",
        },
        {
            title: "2M+",
            subtitle: "Active Users",
        },
        {
            title: "10K+",
            subtitle: "Smart Contracts Deployed",
        },
    ],
    whyChooseUs: {
        title: "The whole gamut, in one place.",
        subtitle: "We’ve developed a knack for all things software.",
        data: [
            {
                title: "Enclave Development",
                subtitle:
                    "Our enclaves and enclave-based solutions are built on Intel SGX and AWS Nitro Enclaves.",
            },
            {
                title: "Software Audits",
                subtitle:
                    "Ensure the reliability and security of your software through thorough audits and testing.",
            },
            {
                title: "Backend Development",
                subtitle:
                    "Create robust and scalable backends for your applications to handle complex operations.",
            },
            {
                title: "Cybersecurity Platforms",
                subtitle:
                    "Unlock the potential of secure services with our customizable security solutions.",
            },
            {
                title: "E-Commerce Marketplaces",
                subtitle:
                    "E-Commerce marketplaces to buy, sell, and trade unique assets with ease",
            },
            {
                title: "Zero Knowledge Proofs",
                subtitle:
                    "Protect user privacy and secure sensitive data with our Zero Knowledge Proof solutions.",
            },
        ],
    },
    buildCool: {
        title: "Let’s build something cool together!",
        subtitle: "Set up a no strings attached call",
        actions: [
            {
                text: "You probably have a few more questions. Let’s have a quick chat.",
                link: calendlyUrl,
                button: "Book a 15 min call",
            },
            {
                text: "Too soon for a call? Send us an email instead!",
                button: "Reach out",
                link: mailUrl,
            },
        ],
    },
    joinUs: {
        title: "Looking to join a cutting-edge software development house? You’ve come to the right place!",
        subtitle: "Send us your resume via email.",
    },
    connectWithUs: [
        {
            title: "Who we are?",
            subtitle: `We're a group of hackers, focussing on building products customers love, learning as we go whether it is Rust, C++ or Go to support backends, the 10th frontend framework or economics to support functional token designs. We love open-source and believe in the power of permissionless systems to create a fair and transparent society.`,
        },
        {
            title: "Let's bring your vision to life, together.",
            subtitle: "Set up a no strings attached call",
        },
    ],
};

import { Menu } from "@mui/icons-material";
import { Box, Drawer, IconButton, styled } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import FlexBetween from "../../atoms/flex-box/FlexBetween";
import FlexBox from "../../atoms/flex-box/FlexBox";
import { H5 } from "../../atoms/Typography";
import {
    maxWidth,
    textColor1,
    textColor1Light,
    themeColor1,
} from "../../utils/theme/themeConstants";
import "./style.css";

function Header() {
    const headerList = [
        {
            name: "Products",
            link: "#products",
        },
        {
            name: "Tech",
            link: "#tech",
        },
        {
            name: "Experience",
            link: "#experience",
        },
        {
            name: "Team",
            link: "#team",
        },
        {
            name: "Build",
            link: "#build",
        },
        {
            name: "Careers",
            link: "#careers",
        },
    ];

    const StyledLink = styled(H5)(({ theme }) => ({
        textDecoration: "none",
        cursor: "pointer",
    }));

    const [open, setOpen] = useState(false);

    return (
        <div
            style={{
                backgroundColor: themeColor1,
            }}
        >
            <FlexBetween
                className="header"
                id="myHeader"
                padding={{
                    xs: "10px 0px",
                    sm: "15px 0px",
                }}
                style={{
                    maxWidth: maxWidth,
                    margin: "auto",
                    textAlign: "center",
                }}
            >
                <FlexBox>
                    <Box
                        display={{
                            xs: "flex",
                            sm: "none",
                        }}
                    >
                        <IconButton onClick={() => setOpen(!open)}>
                            <Menu
                                sx={{
                                    color: "white",
                                    fontSize: "30px",
                                }}
                            />
                        </IconButton>
                    </Box>
                    <Link to="/">
                        <Box
                            width={{
                                xs: "150px",
                                sm: "200px",
                            }}
                        >
                            <img src="/logo/logo-white.svg" alt="logo" />
                        </Box>
                    </Link>
                </FlexBox>
                <FlexBox
                    gap={4}
                    display={{
                        xs: "none",
                        sm: "flex",
                    }}
                >
                    {headerList.map((item, index) => {
                        return (
                            <a href={item.link} key={index}>
                                <StyledLink color={textColor1}>
                                    {item.name}
                                </StyledLink>
                            </a>
                        );
                    })}
                </FlexBox>
                <Drawer open={open} onClose={() => setOpen(false)}>
                    <Box className="h-screen w-40 py-4" bgcolor={themeColor1}>
                        {headerList.map((item, index) => {
                            return (
                                <Box
                                    key={index}
                                    color={textColor1Light}
                                    className="ml-6 my-6"
                                >
                                    <Link to={item.link}>
                                        <StyledLink>{item.name}</StyledLink>
                                    </Link>
                                </Box>
                            );
                        })}
                    </Box>
                </Drawer>
            </FlexBetween>
        </div>
    );
}

export default Header;

import { Box, Grid } from "@mui/material";
import LightButton from "../../atoms/buttons/LightButton";
import { H1, H5 } from "../../atoms/Typography";
import { homeData } from "../../utils/data/homePage";
import {
    textColor1,
    textColor2,
    textColor2Light,
    themeColor3,
} from "../../utils/theme/themeConstants";

const BuildSection = () => {
    const { title, subtitle, actions } = homeData.buildCool;
    return (
        <Box color={textColor2}>
            <H1>{title}</H1>
            <H5 color={textColor2Light} mt={1}>
                {subtitle}
            </H5>
            <Grid
                mt={2}
                container
                spacing={5}
                className="justify-center items-center"
            >
                {actions.map((item, index) => (
                    <Grid item md={5} sm={12} xs={12} key={index}>
                        <Box
                            bgcolor={themeColor3}
                            borderRadius={"5px"}
                            padding={"12px"}
                            className="h-44 flex flex-col items-center justify-center"
                        >
                            <H5 mb={2} color={textColor1}>
                                {item.text}
                            </H5>
                            <LightButton href={item.link}>
                                {item.button}
                            </LightButton>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default BuildSection;

import { useEffect } from "react";
import BuildSection from "../components/page-components/home/BuildSection";
import ExperienceSection from "../components/page-components/home/ExperienceSection";
import JoinUsSection from "../components/page-components/home/JoinUsSection";
import ProductSection from "../components/page-components/home/ProductSection";
import TeamSection from "../components/page-components/home/TeamSection";
import TechSection from "../components/page-components/home/TechSection";
import TopSection from "../components/page-components/home/TopSection";
import AppContainer1 from "../components/sub-components/AppContainer1";
import AppContainer2 from "../components/sub-components/AppContainer2";
import AppLayout from "../components/sub-components/AppLayout";

function Home() {
    const hashId = window.location.hash;

    useEffect(() => {
        if (hashId) {
            // Scroll to the hashId section when the component mounts,scroll 20px
            const element = document.querySelector(hashId);
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                });
            }
        }
    }, [hashId]);

    return (
        <AppLayout>
            <AppContainer1>
                <TopSection />
            </AppContainer1>
            <AppContainer2 elementId="products">
                <ProductSection />
            </AppContainer2>
            <AppContainer1 elementId="tech">
                <TechSection />
            </AppContainer1>
            <AppContainer2 elementId="experience">
                <ExperienceSection />
            </AppContainer2>
            <AppContainer1 elementId={"team"}>
                <TeamSection />
            </AppContainer1>
            <AppContainer2 elementId="build">
                <BuildSection />
            </AppContainer2>
            <AppContainer1 elementId="careers">
                <JoinUsSection />
            </AppContainer1>
        </AppLayout>
    );
}

export default Home;

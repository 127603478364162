import { FC, useState } from "react";
import "./style.css";

const FlipDiv: FC<{
    frontDiv: React.ReactNode;
    backDiv: React.ReactNode;
}> = ({ frontDiv, backDiv }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleMouseEnter = () => {
        setIsFlipped(true);
    };

    const handleMouseLeave = () => {
        setIsFlipped(false);
    };

    return (
        <div
            className={`rounded h-52 flex flex-col items-center justify-center gap-2 px-4 flip-container ${
                isFlipped ? "flip" : ""
            }`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="flipper">
                <div className="container front">{frontDiv}</div>
                <div className="container back">{backDiv}</div>
            </div>
        </div>
    );
};

export default FlipDiv;

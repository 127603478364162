import { Box } from "@mui/material";
import React, { FC } from "react";
import { maxWidth } from "../utils/theme/themeConstants";

// ===================================================
type Props = {
    children: React.ReactNode;
    elementId?: string;
};
// ===================================================

const AppContainer1: FC<Props> = ({ children, elementId }) => {
    return (
        <Box
            id={elementId}
            padding={{
                xs: "60px 20px",
                sm: "120px 0px",
            }}
            style={{
                maxWidth: maxWidth,
                margin: "auto",
                textAlign: "center",
                width: "100%",
            }}
        >
            {children}
        </Box>
    );
};

export default AppContainer1;

import { Box, Grid } from "@mui/material";
import FlexBox from "../../atoms/flex-box/FlexBox";
import { H1, H3, H5 } from "../../atoms/Typography";
import { softwareData } from "../../utils/data/products";
import { textColor1, textColor1Light } from "../../utils/theme/themeConstants";

const TechSection = () => {
    const { techStacks } = softwareData;
    return (
        <Box className="flex flex-col items-center">
            <H1>{techStacks.title}</H1>
            <H5 color={textColor1Light} mt={1} mb={1}>
                {techStacks.subtitle}
            </H5>
            <Grid container className="w-full text-center" spacing={2}>
                {techStacks.data.map((tech, index) => (
                    <Grid item key={index} md={tech.grid} sm={6} xs={12}>
                        <Box
                            key={index}
                            className="w-full flex flex-col items-center gap-2 my-4 rounded"
                        >
                            <H3>{tech.title}</H3>
                            <FlexBox gap={0.7} flexWrap="wrap">
                                {tech.logos.map((logo, index) => (
                                    <Box
                                        key={index}
                                        bgcolor={textColor1}
                                        className="rounded-full p-[5px]"
                                    >
                                        <img
                                            src={logo}
                                            alt="logo"
                                            className="w-full"
                                            style={{
                                                height: "35px",
                                                width: "35px",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </Box>
                                ))}
                            </FlexBox>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default TechSection;

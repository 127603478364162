import { Box } from "@mui/material";
import LightButton from "../../atoms/buttons/LightButton";
import { H1, H5 } from "../../atoms/Typography";
import { mailUrl } from "../../utils/data/common";
import { homeData } from "../../utils/data/homePage";
import { textColor1Light } from "../../utils/theme/themeConstants";

const JoinUsSection = () => {
    const { joinUs } = homeData;
    return (
        <Box className="flex flex-col items-center justify-center">
            <H1>{joinUs.title}</H1>
            <H5 color={textColor1Light} mb={3} mt={1}>
                {joinUs.subtitle}
            </H5>
            <LightButton href={mailUrl}>Contact us</LightButton>
        </Box>
    );
};

export default JoinUsSection;

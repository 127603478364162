export const softwareData = {
    specialists: {
        title: "Helping you solve tomorrow's problems, today.",
        subtitle:
            "Whether we’re with you right at the start or need to meet you halfway. Exploring new verticals or going back to the drawing board, we’re with you, all the way. ",
        subtitle2:
            "Having been in the game for a few years, we’ve worked with individuals and groups at different stages of their development. Be it growth or going to market, Blackfish offers end-to-end software development for all things, under one umbrella.",
        image: "/assets/layer.png",
    },
    techStacks: {
        title: "Technologies used",
        subtitle:
            "We have 20+ years of experience with established technologies while constantly upgrading our skillset to cater to the new kids on the block.",
        data: [
            {
                title: "Backend",
                grid: 3,
                logos: [
                    "/assets/python.svg",
                    "/assets/node.svg",
                    "/assets/dj.png",
                    "/assets/ror.png",
                    "/assets/springboot.png",
                ],
            },
            {
                title: "Fontend",
                grid: 4,
                logos: [
                    "/assets/svelte.svg",
                    "/assets/react.svg",
                    "/assets/vue.svg",
                    "/assets/angular.svg",
                    "/assets/swift.svg",
                    "/assets/js.svg",
                ],
            },
            {
                title: "DevOps",
                grid: 2,
                logos: ["/assets/kubernetes.svg", "/assets/git.svg"],
            },
        ],
    },
};

export const maxWidth = "1100px";

export const sectionColors = ["#2C3C5B", "#F5F5F5", "#0AC5A8"];

export const themeColor1 = sectionColors[0];
export const themeColor2 = sectionColors[1];
export const themeColor3 = sectionColors[2];

// light shade
export const textColor1 = "#F5F5F5";
export const textColor1Light = "#d4d4d9";

// dark shade
export const textColor2 = "#2C3C5B";
export const textColor2Light = "#4d5f75";

import { Box } from "@mui/material";
import LightButton from "../../atoms/buttons/LightButton";
import FlexBox from "../../atoms/flex-box/FlexBox";
import { H1, H5 } from "../../atoms/Typography";
import { aboutData } from "../../utils/data/aboutPage";
import { mailUrl } from "../../utils/data/common";
import { homeData } from "../../utils/data/homePage";
import { textColor1Light } from "../../utils/theme/themeConstants";

const TeamSection = () => {
    const { team } = aboutData;
    const { connectWithUs } = homeData;
    return (
        <Box className="flex flex-col items-center justify-center">
            <H1>{connectWithUs[0].title}</H1>
            <H5 color={textColor1Light} mt={1}>
                {connectWithUs[0].subtitle}
            </H5>
            <FlexBox
                my={6}
                maxWidth={"900px"}
                gap={1}
                flexWrap="wrap"
                justifyContent={"center"}
            >
                {team.data.map((item, index) => (
                    <Box
                        key={index}
                        padding={"5px"}
                        bgcolor={item.bgcolor ?? "none"}
                        borderRadius={item.border ?? "5px"}
                    >
                        <img
                            src={item.logo}
                            alt="logo"
                            style={{
                                objectFit: "contain",
                                height: item.height,
                            }}
                        />
                    </Box>
                ))}
            </FlexBox>
            <LightButton href={mailUrl}>Join us</LightButton>
        </Box>
    );
};

export default TeamSection;
